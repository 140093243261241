import store from "./../../store/index"

const SET_EMAIL = function() {
  return email => store.dispatch("identity/SET_EMAIL", email, { root: true })
}

const ATTEMPT_LOGIN_WITH_PASS = function() {
  return password =>
    store.dispatch("identity/ATTEMPT_LOGIN_WITH_PASS", password, { root: true })
}

export let loginScript = {
  dynamicScript: true,
  buildScript: () => buildScript()
}

async function buildScript() {
  loginScript.scriptElements = [
    {
      id: "login_intro",
      type: "DecisionElement",
      query: "IS_USER_LOGGED_IN",
      responseSequence: {
        true: ["login_logged", "end_script"],
        previouslyVisited: [
          "previously_visited",
          "login_password-info",
          "login_password",
          "login_success",
          "end_script"
        ],
        false: [
          "login_unlogged",
          "login_email",
          "login_password-info",
          "login_password",
          "login_success",
          "end_script"
        ]
      }
    },
    {
      id: "previously_visited",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Looks like you have previously visited"
      },
      duration: 2000
    },
    {
      id: "login_logged",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Okay [* NAME *], let's see what we can do"
      },
      duration: 2000
    },
    {
      id: "login_unlogged",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Well, before we go any further I'll need you to log in"
      },
      duration: 2000
    },
    {
      id: "login_email",
      type: "InputElement",
      conversationElement: {
        messageType: "ResponseInput",
        label: "Email",
        type: "email",
        name: "login-email",
        handleSubmit: SET_EMAIL(),
        text: "" // TODO: Look into why I have to include this here
      }
      //TODO it would be great if the responses would work here as well
    },
    {
      id: "login_password-info",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Awesome, now let's get your password"
      },
      duration: 2000
    },
    {
      id: "login_password",
      type: "InputElement",
      conversationElement: {
        messageType: "ResponseInput",
        label: "Password",
        type: "password",
        name: "login-password",
        handleSubmit: ATTEMPT_LOGIN_WITH_PASS(),
        text: ""
      }
    },
    {
      id: "login_action",
      type: "InputElement",
      conversationElement: {
        messageType: "IdentityMessage",
        text: "Enter your login details here",
        fields: [
          {
            type: "text",
            label: "Name",
            name: "user"
          },
          {
            type: "password",
            label: "Password",
            name: "password"
          }
        ],
        callback: "AUTH_LOGIN"
      },
      responseSequence: {
        login: ["login_success", "end_script"]
      }
    },
    {
      id: "login_success",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Nice one! Thanks [* NAME *]"
      },
      duration: 2000
    },
    {
      id: "end_script",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: []
    }
  ]
}

export default loginScript
