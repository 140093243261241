import store from "./../../store/index"

const selectedServicePayload = function() {
  const _state = store.getters["chat/tutorialBookingPayload"]
  return _state
}

export let tutorialScript = {
  dynamicScript: true,
  buildScript: () => buildScript()
}

async function buildScript() {
  tutorialScript.scriptElements = [
    {
      id: "tutorial_booking_intro",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "Hey [* NAME *], I can help you book the perfect tutorial for your hair! Let's go."
      },
      duration: 2000
    },
    {
      id: "booking_form",
      type: "InputElement",
      conversationElement: {
        messageType: "ComponentMessage",
        text: null,
        component: "TutorialBookingWidget",
        payload: selectedServicePayload()
      },
      duration: 1000,
      responseSequence: {
        submit: [
          "booking_confirmed",
          "send_confirmation",
          "show_confirmation",
          "end_script"
        ],
        cancel: ["booking_abandoned"]
      }
    },
    {
      id: "booking_confirmed",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Great! You're all set. Here's your confirmation:"
      },
      duration: 2000
    },
    {
      id: "send_confirmation",
      type: "ActionElement",
      action: "SEND_CONFIRMATION_TO_CHANNEL",
      payload: null
    },
    {
      id: "booking_abandoned",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "No problem! If you change your mind just let us know, we'll be happy to get you booked in."
      },
      duration: 2000
    },
    {
      id: "end_script",
      type: "DecisionElement",
      query: "IS_ACTIVE_CHAT",
      responseSequence: {
        true: ["back_to_agent"],
        false: ["default_end"]
      }
    },
    {
      id: "back_to_agent",
      type: "ActionElement",
      action: "INIT_ASYNC_CHAT",
      payload: null
    },
    {
      id: "default_end",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: []
    }
  ]
}

export default tutorialScript
