export default {
  scriptElements: [
    {
      id: "intro",
      type: "DecisionElement",
      query: "IS_RETURN_TO_CHAT",
      responseSequence: {
        true: ["intro_return", "landing_02"],
        false: ["intro_new", "landing_01", "landing_02"]
      }
    },
    {
      id: "intro_new",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Hi, welcome to Bleach"
      },
      duration: 2000
    },
    {
      id: "intro_return",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Hello again!"
      },
      duration: 2000
    },
    {
      id: "landing_01",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "I'm your helpful Bleach Bot."
      },
      duration: 2000
    },
    {
      id: "landing_02",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: " Liking this look? We can help you nail it."
      },
      duration: 2000
    },
    {
      id: "landing_03",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Let's start by getting to know a little bit more about you."
      },
      duration: 2000
    },
    {
      id: "redirect_to_looks",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: ["loginScript", "hairStatePreCheck", "looksSelectionScript"]
    }
  ]
}
