export default {
  scriptElements: [
    {
      id: "intro",
      type: "DecisionElement",
      query: "IS_RETURN_TO_CHAT",
      responseSequence: {
        true: ["intro_return", "landing_02a"],
        false: ["intro_new", "landing_01", "landing_02a"]
      }
    },
    {
      id: "intro_new",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Hi, welcome to Bleach"
      },
      duration: 2000
    },
    {
      id: "intro_return",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "Hello again!"
      },
      duration: 2000
    },
    {
      id: "landing_01",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "I'm your helpful Bleach Bot."
      },
      duration: 2000
    },
    {
      id: "landing_02a",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text:
          "Looking for a new look? Let's start by getting to know a little bit more about you."
      },
      duration: 2000
    },
    {
      id: "landing_02b",
      type: "MessageElement",
      conversationElement: {
        messageType: "DefaultMessage",
        text: "What are you looking to do to your hair?"
      },
      duration: 2000
    },
    {
      id: "landing_03",
      type: "InputElement",
      conversationElement: {
        messageType: "ResponseMessage",
        responseType: "ResponseButtonSet",
        id: "example_id_for_question_how_are_you",
        multiSelect: 1,
        text: null,
        answers: [
          {
            id: "a",
            text: "Get this look!"
          },
          {
            id: "b",
            text: "Fix my mess!"
          }
        ]
      },
      duration: 1000,
      responseSequence: {
        a: ["redirect_to_looks"],
        b: ["redirect_to_cs"]
      }
    },
    {
      id: "redirect_to_looks",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: ["loginScript", "hairStatePreCheck", "looksSelectionScript"]
    },
    {
      id: "redirect_to_cs",
      type: "ActionElement",
      action: "APPEND_NEW_SCRIPT",
      payload: ["loginScript", "chatLaunchScript"]
    }
  ]
}
